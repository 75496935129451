import React from "react"
import { Layout } from '../layout'
export default function LunchWithSanta() {
  return <Layout sidebar={false}>
    <div className="lunch-with-santa">
      <img
        src="/lunch_with_santa.jpeg"
        alt="Lunch With Santa at Eric's Italian Bistro"
      />
    </div>
  </Layout>
}
